import * as Environment from "../base/Environment.js";

import {RenderContext, AnimatableSprite} from "../base/Display2D.js";

//
// MouseTracker extends AnimatableSprite
//

export const MouseTracker = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

MouseTracker.prototype = Object.create (AnimatableSprite.prototype);

MouseTracker.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	this.mouseSpeed = [0, 0];

	const spot = this.spot = document.createElement ("div");
	spot.classList.add ("mouse-spot");
	
	element.appendChild (spot);
	
	this.addListener ("mouseover", this.beginTracking, this);
	this.addListener ("mousemove", this.doTracking, this);
	this.addListener ("mouseout", this.endTracking, this);
	
	/*
	if (Environment.FAST_PASS) {
		window.setTimeout (function () {
			this.setTargetMousePosition ([100, 100], true);
			
		}.bind (this), 250);
		
	}
	*/
	
};

MouseTracker.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	if (!this.isMouseOver)
		this.setTargetMouseToCenter ();
	
};

MouseTracker.prototype.beginTracking = function (sender) {
	this.isMouseOver = true;
	
	// this.addListener ("mousemove", this.doTracking, this);
	
};

MouseTracker.prototype.doTracking = function (sender) {
	const event = sender.currentEvent;
	const mouse = this.touchDescriptionForObject (event);
	
	if (Environment.IS_SAFARI) {
		const viewSize = this.getStage ().size;
		if (mouse [0] <= 0 ||
			mouse [0] >= viewSize [0] ||
			mouse [1] <= 0 ||
			mouse [1] >= viewSize [1]) {
			
			this.endTracking ();
			return;
			
		}
		
	}
	
	if (!this.isMouseOver)
		this.isMouseOver = true;
	
	this.setTargetMousePosition (mouse, true);
	
};

MouseTracker.prototype.endTracking = function (sender) {
	this.isMouseOver = false;
	
	this.setTargetMouseToCenter (true);
	
	// this.removeListener ("mousemove", this.doTracking, this);
	
};

MouseTracker.prototype.setTargetMouseToCenter = function (animated) {
	const viewSize = this.viewSize;
	
	this.setTargetMousePosition ([
		viewSize [0] * .5,
		viewSize [1] * .5
		
	], animated);
	
};

MouseTracker.prototype.setTargetMousePosition = function (targetMouse, animated) {
	const lastTargetMouse = this.targetMouse;
	
	if (lastTargetMouse && animated) {
		this.targetMouse = targetMouse.concat ();
		this.addRunLoopHandler ("processTracking");
		
	} else {
		this.currentMouse = targetMouse.concat ();
		this.targetMouse = targetMouse.concat ();
		this.drawSpot ();
		
		this.removeRunLoopHandler ("processTracking");
		
	}
	
};

MouseTracker.prototype.drawSpot = function () {
	const viewSize = this.viewSize;
	if (!viewSize)
		return;
	
	const mouse = this.currentMouse;
	
	const spot = this.spot;
	spot.style.left = mouse [0] + "px";
	spot.style.top = mouse [1] + "px";
	
	spot.style.opacity = this.isMouseOver ? 1 : .2;
	
	this.relativePosition = [
		(mouse [0] /
			viewSize [0]) * 2 - 1,
		(mouse [1] /
			viewSize [1]) * 2 - 1
		
	];
	
	this.didUpdateMouse = true;
	this.dispatchEvent ("updateMouse");
	
};

MouseTracker.prototype.accelerationFactor = .1;
MouseTracker.prototype.dampFactor = .8;

MouseTracker.prototype.processTracking = function () {
	const currentMouse = this.currentMouse;
	const targetMouse = this.targetMouse;
	
	const mouseDelta = [
		targetMouse [0] - currentMouse [0],
		targetMouse [1] - currentMouse [1]
		
	];
	
	const mouseSpeed = this.mouseSpeed;
	
	const accelerationFactor = this.accelerationFactor; // .1;
	
	mouseSpeed [0] += mouseDelta [0] * accelerationFactor;
	mouseSpeed [1] += mouseDelta [1] * accelerationFactor;
	
	const dampFactor = this.dampFactor; // .8;
	
	mouseSpeed [0] *= dampFactor;
	mouseSpeed [1] *= dampFactor;
	
	currentMouse [0] += mouseSpeed [0];
	currentMouse [1] += mouseSpeed [1];
	
	this.drawSpot ();
	
	const deltaLength = Math.sqrt (
		mouseDelta [0] * mouseDelta [0] +
			mouseDelta [1] * mouseDelta [1]
		
	);
	
	const speedLength = Math.sqrt (
		mouseSpeed [0] * mouseSpeed [0] +
			mouseSpeed [1] * mouseSpeed [1]
		
	);
	
	const epsilon = .01;
	
	if (deltaLength < epsilon && speedLength < epsilon) {
		this.removeRunLoopHandler ("processTracking");
		
	}
	
};
