import * as Environment from "./Environment.js";
import {Dispatcher} from "./Base.js";

//
// AnimationTimer extends Dispatcher
//

var AnimationTimer = function () {
	Dispatcher.apply (this);
	
};

AnimationTimer.prototype = Object.create (Dispatcher.prototype);

AnimationTimer.prototype.addListener = function (eventType, callback, listener) {
	var listenerDescriptions = this.events [eventType];
	var lastListenerCount = listenerDescriptions ?
		listenerDescriptions.length : 0;
	
	Dispatcher.prototype.addListener.apply (this, arguments);
	
	if (!lastListenerCount && eventType == "fire" && this.events [eventType].length == 1)
		this.start ();
	
};

AnimationTimer.prototype.removeListener = function (eventType, callback, listener) {
	if (!this.events [eventType])
		return;
	
	Dispatcher.prototype.removeListener.apply (this, arguments);
	
	if (eventType == "fire" && !this.events [eventType])
		this.stop ();
	
};

AnimationTimer.prototype.start = function () {
	// console.log ("animation timer started.");
	
	var that = this;
	
	var requestAnimationFrame = window.requestAnimationFrame ||
		window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame;
	
	if (!Environment.IS_MOBILE_SAFARI && requestAnimationFrame) {
		var processAnimationFrame = this.processAnimationFrame =
			function (event) {
				that.fire ();
				requestAnimationFrame (that.processAnimationFrame);
				
			};
		
		requestAnimationFrame (processAnimationFrame);
		
	} else {
		this.renderTimer = window.setInterval (function () {
			that.fire ();
			
		}, 1000 / 60.);
		
	}
	
};

AnimationTimer.prototype.timeDelta = 0;
AnimationTimer.prototype.framesDelta = 0;

AnimationTimer.prototype.fire = function () {
	var lastTime = this.lastTime;
	var time = new Date ().getTime ();
	
	var timeDelta = this.timeDelta = (time - lastTime) || 0;
	this.framesDelta = timeDelta / (1000 / 60.);
	
	this.lastTime = time;
	
	this.dispatchEvent ("preFire");
	this.dispatchEvent ("fire");
	
};

AnimationTimer.prototype.stop = function () {
	// console.log ("animation timer stopped.");
	
	if (this.processAnimationFrame) {
		this.processAnimationFrame = function (event) {};
		
	} else {
		if (this.renderTimer) {
			window.clearInterval (this.renderTimer);
			delete this.renderTimer;
			
		}
		
	}
	
};

//
// RenderContext extends Dispatcher
//

export const RenderContext = function () {
	Dispatcher.apply (this);
	
	if (!RenderContext.sharedInstance)
		RenderContext.sharedInstance = this;
	
	this.animationTimer = AnimationTimer.getSharedInstance ();
	
	var resizeDispatcher = this.resizeDispatcher = RenderContext.resizeDispatcher;
	if (!resizeDispatcher) {
		resizeDispatcher = this.resizeDispatcher = RenderContext.resizeDispatcher =
			new RenderContext.ResizeDispatcher ();
		
		window.addEventListener ("resize", function (event) {
			if (Environment.IS_IE) {
				var viewportSize = RenderContext.getViewportSize ();
				
				var width = viewportSize [0];
				var height = viewportSize [1];
				
				if (RenderContext.resizeDispatcher.lastWidth == width &&
					RenderContext.resizeDispatcher.lastHeight == height)
					return;
				
				RenderContext.resizeDispatcher.lastWidth = width;
				RenderContext.resizeDispatcher.lastHeight = height;
				
			}
			resizeDispatcher.currentEvent = event || window.event;
			resizeDispatcher.resizeContext ();
			
		}, false);
		
		if (!window.IS_IN_IFRAME) {
			document.addEventListener ("visibilitychange", function (event) {
				if (document.visibilityState == "visible") {
					resizeDispatcher.currentEvent = event || window.event;
					resizeDispatcher.resizeContext ();
					
				}
				
			}, false);
			
			window.addEventListener ("scroll", function (event) {
				resizeDispatcher.currentEvent = event || window.event;
				resizeDispatcher.scrollContext ();
				
			});
			
		}
		
	}
	
	resizeDispatcher.addListener ("resize", function (resizeDispatcher) {
		this.dispatchEvent ("resize");
		
	}, this);
	
	resizeDispatcher.addListener ("postResize", function (resizeDispatcher) {
		this.dispatchEvent ("postResize");
		
	}, this);
	
};

RenderContext.prototype = Object.create (Dispatcher.prototype);

RenderContext.fromElement = function (element) {
	var context = new RenderContext ();
	
	context.setUpWithElement (element);
	
	return context;
	
};

RenderContext.prototype.setUpWithElement = function (element) {
	this.rootElement = element;
	
};

RenderContext.prototype.getWindowSize = function () {
	return [
		Math.max (0,
			this.rootElement.offsetWidth
			
		),
		Math.max (0,
			this.rootElement.offsetHeight
			
		)
		
	];
	
};

RenderContext.getViewportSize = function () {
	return [
		Math.max (0, // 270,
			Math.max (
				document.documentElement.clientWidth,
				window.innerWidth || 0
				
			)
			
		),
		Math.max (0, // 270,
			Math.max (
				document.documentElement.clientHeight,
				window.innerHeight || 0
				
			)
			
		)
		
	];
	
};

RenderContext.prototype.getViewportSize = function () {
	return RenderContext.getViewportSize ();
	
};

RenderContext.getDocumentSize = function () {
	var body = document.body;
    var documentElement = document.documentElement;
	
	return [
		Math.max (
			body.scrollWidth, body.offsetWidth,
			documentElement.clientWidth, documentElement.scrollWidth, documentElement.offsetWidth
			
		),
		Math.max (
			body.scrollHeight, body.offsetHeight,
			documentElement.clientHeight, documentElement.scrollHeight, documentElement.offsetHeight
			
		)
		
	];
	
};

RenderContext.getScrollOffset = function () {
	return document.all ? [
		document.documentElement.scrollLeft || document.body.scrollLeft,
		document.documentElement.scrollTop || document.body.scrollTop
		
	] : [
		window.pageXOffset,
		window.pageYOffset
		
	];
	
};

//
// RenderContext.ResizeDispatcher extends Dispatcher
//

RenderContext.ResizeDispatcher = function () {
	Dispatcher.apply (this, arguments);
	
};

RenderContext.ResizeDispatcher.prototype = Object.create (Dispatcher.prototype);

RenderContext.ResizeDispatcher.prototype.resizeContext = function () {
	this.updateModeForViewSize ();
	
	this.dispatchEvent ("preResize");
	this.dispatchEvent ("resize");
	this.dispatchEvent ("postResize");
	
};

RenderContext.ResizeDispatcher.prototype.scrollContext = function () {
	this.dispatchEvent ("scroll");
	
};

RenderContext.ResizeDispatcher.prototype.takeModeList = function (modeList) {
	this.modeList = modeList;
	
	this.resizeContext ();
	
};

RenderContext.ResizeDispatcher.prototype.updateModeForViewSize = function () {
	var modeList = this.modeList;
	if (!modeList)
		return;
	
	// trace ("try update mode");
	
	var viewPortSize = RenderContext.getViewportSize ();
	var viewPortWidth = viewPortSize [0];
	
	for (var i = modeList.length; i--;) {
		var mode = modeList [i];
		if (viewPortWidth >= mode.width)
			break;
		
	}
	
	var lastMode = this.mode;
	if (lastMode == mode)
		return;
	
	this.mode = mode;
	
	var nextClassName = mode.className;
	if (this.className != nextClassName) {
		document.body.className = nextClassName;
		this.className = nextClassName;
		
	}
	
	// trace ("set mode", JSON.stringify (mode));
	this.dispatchEvent ("toggleMode");
	
};

RenderContext.ResizeDispatcher.prototype.setBodyClass = function (bodyClass, priority) {
	if (IS_TOUCH_DEVICE)
		return;
	
	var bodyClasses = this.bodyClasses;
	if (!bodyClasses)
		bodyClasses = this.bodyClasses = new Array ();
	
	if (!bodyClass)
		bodyClass = "";
	if (isNaN (priority))
		priority = 1;
	
	if (bodyClasses [priority] != bodyClass) {
		bodyClasses [priority] = bodyClass;
		
		var bodyClassName = "";
		for (var i = 0; i < bodyClasses.length; i++) {
			var className = bodyClasses [i];
			if (!className)
				continue;
			
			bodyClassName = className;
			break;
			
		}
		
		this.bodyClassName = bodyClassName;
		document.body.className = bodyClassName + (this.className ? " " + this.className : "");
		
	}
	
};

//
// abstract static Animatable
//

export const Animatable = function () {
	this.states = new Object ();
	this.runLoopHandlers = new Array ();
	
};

Animatable.addRunLoopHandler = function (handlerName) {
	var handler = this [handlerName];
	
	if (!(handler && handler instanceof Function)) {
		console.log ("tried to register invalid function call " + handlerName + ".");
		return;
		
	}
	
	var runLoopHandlers = this.runLoopHandlers;
	if (runLoopHandlers.indexOf (handlerName) >= 0)
		return;
	
	runLoopHandlers.push (handlerName);
	this.context.addListener ("enterFrame", this.runLoop, this);
	
};

Animatable.removeRunLoopHandler = function (handlerName) {
	var runLoopHandlers = this.runLoopHandlers;
	var handlerIndex = runLoopHandlers.indexOf (handlerName);
	
	if (handlerIndex >= 0)
		runLoopHandlers.splice (handlerIndex, 1);
	
	if (!runLoopHandlers.length)
		this.context.removeListener ("enterFrame", this.runLoop, this);
	
};

Animatable.runLoop = function () {
	var runLoopHandlers = this.runLoopHandlers;
	
	for (var i = runLoopHandlers.length; i--;) {
		var handlerName = runLoopHandlers [i];
		if (!handlerName)
			continue;
		
//		try {
			this [handlerName] ();
			
/*		} catch (error) {
			console.log ("failed processing " + handlerName + ".");
			this.removeRunLoopHandler (handlerName);
			throw error;
			
		}
*/		
	}
	
};

Animatable.startAnimation = function (animationKey, properties) {
	var state = this.states [animationKey];
	if (!state) {
		state = new Object ();
		this.states [animationKey] = state;
		
	}
	
	for (var key in properties)
		state [key] = properties [key];
	
	if (!state.phase)
		state.phase = 0;
	if (!state.rate)
		state.rate = .05;
	
	if ((state.direction <= 0 && state.phase == 0.) ||
		(state.direction > 0 && state.phase == 1.))
		return;
	
	var handlerName = "animate" + animationKey;
	this.addRunLoopHandler (handlerName);
	this [handlerName] ();
	
};

Animatable.stopAnimation = function (animationKey) {
	var state = this.states [animationKey];
	var handlerName = "animate" + animationKey;
	
	if (state) {
		var targetPhase = state.direction > 0 ? 1. : 0.;
		if (state.phase != targetPhase) {
			state.phase = targetPhase;
			this.dispatchEvent ("complete" + animationKey);
			this [handlerName] ();
			
		}
		
	}
	this.removeRunLoopHandler (handlerName);
	
};

Animatable.updatedState = function (animationKey) {
	var state = this.states [animationKey];
	
	var framesDelta = this.context.animationTimer.framesDelta || 1;
	if (framesDelta >= .85 && framesDelta <= 1 / .85)
		framesDelta = 1;
	
	var phase = state.direction > 0 ?
		Math.min (1., state.phase + state.rate * (1 + framesDelta) / 2) :
		Math.max (0., state.phase - state.rate * (1 + framesDelta) / 2);
	
	state.phase = phase;
	
	if (phase == 0. || phase == 1.) {
		this.removeRunLoopHandler ("animate" + animationKey);
		this.dispatchEvent ("complete" + animationKey);
		
	}
	return state;
	
};

//

function createCSSRuleBySelector (selector, recursionDepth) {
	var baseStyleSheet = this.baseStyleSheet;
	if (!baseStyleSheet) {
		var styleElement = document.createElement ("style");
		document.getElementsByTagName ("head") [0].appendChild (styleElement);
		if (!window.createPopup) // for Safari
			styleElement.appendChild (document.createTextNode (""));
		
		var styleSheets = document.styleSheets;
		
		for (var i = styleSheets.length; i--;) {
			var styleSheet = styleSheets [i];
			try {
				var cssRules = styleSheet.cssRules;
				if (!cssRules)
					continue;
				
				baseStyleSheet = this.baseStyleSheet = styleSheet;
				break;
				
			} catch (exception) {
				
			}
			
		}
		if (!baseStyleSheet)
			baseStyleSheet = this.baseStyleSheet = styleSheets [styleSheets.length - 1];
		
	}
	
	var cssRules = baseStyleSheet && baseStyleSheet.cssRules;
	
	if (!cssRules) {
		if (recursionDepth)
			return;
		
		styleElement = document.createElement ("style");
		styleElement.setAttribute ("type", "text/css");
		document.getElementsByTagName ("head") [0].appendChild (styleElement);
		
		createCSSRuleBySelector (selector, 1);
		return;
		
	}
	
	var numCSSRules = cssRules.length;
	
	if (baseStyleSheet.insertRule)
		baseStyleSheet.insertRule (selector + " {}", numCSSRules);
	else
		baseStyleSheet.addRule (selector, "");
	
	return cssRules [numCSSRules];
	
}
