import * as Environment from "./base/Environment.js";

import {Stage} from "./base/Display2D.js";
import {Site} from "./site/Site.js";

//
// setup
//

function setUpContent () {
	var controllerElement = document.querySelector ("main") || document.body;
	var controller = Stage.fromElement.call (
		Site, controllerElement
		
	);
	
};

if (Environment.IS_IE)
	document.body.classList.add ("IE");

if (Environment.IS_SAFARI)
	document.body.classList.add ("SAFARI");

window.addEventListener ("load", setUpContent);
