import * as Environment from "../base/Environment.js";

import {RenderContext, AnimatableSprite} from "../base/Display2D.js";
import {SiteSection} from "./SiteSection.js";

//
// OverlayController extends AnimatableSprite
//

export const OverlayController = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	const element = this.element;
	element.classList.add ("overlay-controller");
	
	const closeButton = this.closeButton = document.createElement ("a");
	closeButton.href = "#";
	closeButton.classList.add ("view-close-button");
	
	closeButton.addEventListener ("click", function () {
		this.getStage ().closeOverlay ();
		
	}.bind (this));
	
	element.addEventListener ("click", function (event) {
		if (event.target != this.element.lastElementChild &&
			event.target != this.element.lastElementChild.firstElementChild)
			return;
		
		this.getStage ().closeOverlay ();
		
	}.bind (this));
	
	element.appendChild (closeButton);
	
};

OverlayController.prototype = Object.create (AnimatableSprite.prototype);

OverlayController.prototype.takeContentElement = function (contentElement) {
	contentElement = this.contentElement = contentElement.cloneNode (true);
	
	this.clearContent ();
	
	const element = this.element;
	
	const iframe = contentElement.querySelector ("iframe");
	if (iframe)
		iframe.src = iframe.getAttribute ("data-src");
	
	element.appendChild (contentElement);
	
};

OverlayController.prototype.clearContent = function () {
	const element = this.element;
	while (element.children.length > 1)
		element.removeChild (element.lastElementChild);
	
};

OverlayController.prototype.awake = function () {
	if (this.isAwake)
		return;
	
	this.isAwake = true;
	
};

OverlayController.prototype.sleep = function () {
	if (!this.isAwake)
		return;
	
	this.isAwake = false;
	
};

OverlayController.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	const element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";
	
};

OverlayController.prototype.open = function () {
	this.awake ();
	
	this.startAnimation ("Expansion", {direction: 1, rate: SiteSection.TRANSITION_SPEED}); // * .75
	
};

OverlayController.prototype.close = function () {
	this.startAnimation ("Expansion", {direction: 0, rate: SiteSection.TRANSITION_SPEED});
	
};

OverlayController.prototype.animateExpansion = function () {
	const state = this.updatedState ("Expansion");
	
	let t = state.phase;
	const element = this.element;
	const contentElement = this.contentElement;
	
	if (t == 0) {
		element.style.display = "none";
		
		this.sleep ();
	
	} else {
		t = t * .5 + .5;
		
		if (element.style.display)
			element.style.display = "";
		
		t = .5 - Math.cos (Math.PI * t) * .5;
		t = .5 - Math.cos (Math.PI * t) * .5;
		
		const scale = Math.pow (1.75, t - 1);
		
		const t_ = (t - .5) * 2;
		
		element.style.opacity = t_;
		element.style.backgroundColor = "rgba(246, 246, 246, " + Math.min (.925, (t_ - .125) * 2) + ")";
		
		contentElement.style.transform = "scale(" + scale + ")";
		
		const closeButton = this.closeButton;
		closeButton.style.opacity = t * 5 - 4;
		
	}
	
};
