import * as Environment from "../base/Environment.js";

import {LoaderSystem, JSONLoaderJob} from "../base/Loader.js";
import {RenderContext, AnimatableSprite} from "../base/Display2D.js";

import {Site} from "./Site.js";

//
// TourSplitView extends AnimatableSprite
//

export const TourSplitView = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

TourSplitView.prototype = Object.create (AnimatableSprite.prototype);

TourSplitView.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	if (!Site.ENABLE_CHAT)
		element.classList.add ("chat-disabled");

	const closeButton = element.querySelector (".view-close-button");
	closeButton.addEventListener ("click", function () {
		const parent = this.parent;
		if (parent.showsSplitView)
			parent.toggleSplitView ();
		
	}.bind (this));
	
	
	const form = this.form = element.querySelector ("form");
	form.addEventListener ("submit", this.submitForm.bind (this));
	
	const textArea = this.textArea = form.querySelector ("textarea");
	textArea.addEventListener ("input", function (event) {
		const value = textArea.value;
		if (value)
			sendButton.classList.remove ("disabled");
		else
			sendButton.classList.add ("disabled");
		
	}.bind (this));
	
	const sendButton = this.sendButton = form.querySelector (".action-button--send");
	sendButton.addEventListener ("click", this.clickSendButton.bind (this));
	sendButton.classList.add ("disabled");
	
	const successMessage = this.successMessage = form.querySelector (".success-message");
	const errorMessage = this.errorMessage = form.querySelector (".error-message");
	
	const restartButton = this.restartButton = form.querySelector (".action-button--restart");
	if (restartButton)
		restartButton.addEventListener ("click", this.clickRestartButton.bind (this));
	
	this.setIsSent (false);
	
};

TourSplitView.prototype.awake = function () {
	if (this.isAwake)
		return;
	
	this.isAwake = true;
	
	window.setTimeout (function () {
		this.textArea.focus ();
		
	}.bind (this), 750);
	
};

TourSplitView.prototype.sleep = function () {
	if (!this.isAwake)
		return;
	
	this.isAwake = false;
	
};

TourSplitView.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	const element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";
	
};

TourSplitView.prototype.setIsSent = function (isSent) {
	this.isSent = isSent;
	
	const form = this.form;
	const sendButton = this.sendButton;

	const successMessage = this.successMessage;
	const restartButton = this.restartButton;
	
	if (isSent) {
		form.classList.add ("sent");
		sendButton.style.display = "none";
		
		successMessage.style.display = "";
		if (restartButton)
			restartButton.style.display = "";
		
	} else {
		form.classList.remove ("sent");
		sendButton.style.display = "";
		
		successMessage.style.display = "none";
		if (restartButton)
			restartButton.style.display = "none";
		
	}
	
};

TourSplitView.prototype.submitForm = function (event) {
	event.preventDefault ();
	
	this.sendForm ();
	
};

TourSplitView.prototype.clickSendButton = function (event) {
	event.preventDefault ();
	
	this.sendForm ();
	
};

TourSplitView.prototype.backendServiceURL = "/api/sendmail";

TourSplitView.prototype.sendForm = function (event) {
	const textArea = this.textArea;
	const message = textArea.value.trim ();
	
	if (!message)
		return;
	
	this.setErrorMessage ();
	
	this.sendButton.classList.add ("disabled");
	this.textArea.setAttribute ("disabled", "");
	
	const backendServiceURL = this.backendServiceURL;
	
	const loader = LoaderSystem.getSharedInstance ();
	const job = loader.jobForPathOfClass (
		backendServiceURL,
		JSONLoaderJob,
		true
		
	);
	
	job.method = "POST";
	job.parameters = {
		text: message
		
	};
	
	job.addListener ("complete", this.completeSending, this);
	job.addListener ("ioError", this.ioErrorSending, this);
	
	if (false && Environment.FAST_PASS)
		this.completeSending (job);
	else
		loader.enqueueJob (job);
	
};

TourSplitView.prototype.completeSending = function (job) {
	const data = job.data;
	
	if (data.message == "success") {
		this.setIsSent (true);
		
	} else {
		this.ioErrorSending (job);
		
	}
	
	this.sendButton.classList.remove ("disabled");
	
};

TourSplitView.prototype.ioErrorSending = function (job) {
	console.log ("transmission failed.", job);
	
	this.sendButton.classList.remove ("disabled");
	this.textArea.removeAttribute ("disabled");
	
	this.setErrorMessage (
		(job.data && job.data.message) ||
		"Ein Netzwerkfehler ist aufgetreten."
		
	);
	
};

TourSplitView.prototype.setErrorMessage = function (message) {
	const errorMessage = this.errorMessage;
	
	if (message) {
		errorMessage.innerHTML = "";
		errorMessage.appendChild (document.createTextNode (message));
		
		errorMessage.style.display = "";
		
	} else {
		errorMessage.style.display = "none";
		
	}
	
};

TourSplitView.prototype.clickRestartButton = function (event) {
	event.preventDefault ();
	
	this.textArea.removeAttribute ("disabled");
	this.textArea.value = "";
	this.textArea.focus ();
	
	this.sendButton.classList.add ("disabled");
	
	this.setIsSent (false);
	
};

//
// ExpertTourSplitView extends TourSplitView
//

export const ExpertTourSplitView = function (context) {
	TourSplitView.apply (this, arguments);
	
};

ExpertTourSplitView.prototype = Object.create (TourSplitView.prototype);

ExpertTourSplitView.prototype.backendServiceURL = "/api/sendmail";

ExpertTourSplitView.formSelector = ".expert-split-view";

//
// BonusTourSplitView extends TourSplitView
//

export const BonusTourSplitView = function (context) {
	TourSplitView.apply (this, arguments);
	
};

BonusTourSplitView.prototype = Object.create (TourSplitView.prototype);

BonusTourSplitView.prototype.backendServiceURL = "/api/credits";

BonusTourSplitView.formSelector = ".credit-split-view";
