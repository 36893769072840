import * as Environment from "../base/Environment.js";

import {RenderContext, AnimatableSprite} from "../base/Display2D.js";

//
// ChatController extends AnimatableSprite
//

export const ChatController = function (context) {
	AnimatableSprite.apply (this, arguments);

};

ChatController.prototype = Object.create (AnimatableSprite.prototype);

ChatController.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;

	const title = this.title = element.querySelector ("h2");

	const closeButton = element.querySelector (".view-close-button");
	closeButton.addEventListener ("click", function () {
		const stage = this.getStage ();
		if (stage.showsChat)
			stage.openChat ("close");

	}.bind (this));

	const iFrame = this.iFrame = element.querySelector ("iframe.chat-i-frame");
	const iFrameSource = this.iFrameSource = iFrame.getAttribute ("data-src");
	
	window.setTimeout (function () {
		const iFrame = this.iFrame;
		const iFrameSource = this.iFrameSource;
		
		if (iFrame.src != iFrameSource) {
			iFrame.src = iFrameSource;
			this.iFrameSource = iFrame.src;
			
		}
		
	}.bind (this), 125);
	
};

ChatController.prototype.channelName = -1;

ChatController.prototype.setChannelName = function (channelName, delayed) {
	if (this.channelName == channelName)
		return;
	
	this.channelName = channelName;
	
	const title = this.title;
	
	/*
	const exparea_config = window.exparea_config;
	const chatTitleSend = exparea_config ?
		exparea_config.i18n ['chat.title.send'] :
		"Chat mit";
	const chatTitleMention = exparea_config ?
		exparea_config.i18n ['chat.title.mention'] :
		"Um einzelne Teilnehmer anzuschreiben, beginnen Sie ihre Nachricht mit @ und wählen den Teilnehmer in der Liste aus.";
	*/
	
	const titleText = ""; // channelName ? chatTitleSend + " " + channelName : chatTitleMention;

	title.innerHTML = titleText;

	const iFrame = this.iFrame;
	let iFrameSource = this.iFrameSource;

	/*
	if (channelName)
		iFrameSource = iFrameSource.replace (".html", "-" + channelName + ".html");
	else
		iFrameSource = window.exparea_config.urls.chat;
	*/
	
	if (false && delayed && !channelName && !Environment.FAST_PASS) {
		iFrame.src = "about:blank";
		window.setTimeout (function () {
			if (iFrame.src != iFrameSource)
				iFrame.src = iFrameSource;
			
		}, 550);
		
	} else {
		if (iFrame.src != iFrameSource)
			iFrame.src = iFrameSource;
		
	}
	
	this.iFrameSource = iFrame.src;

};

ChatController.prototype.awake = function () {
	if (this.isAwake)
		return;

	this.isAwake = true;

};

ChatController.prototype.sleep = function () {
	if (!this.isAwake)
		return;

	this.isAwake = false;

};

ChatController.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;

	const element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";

};
